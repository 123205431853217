import React from 'react';
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';

const Stories = () => {
    const stories = [
        {
            title: 'Capturing Love: A Beautiful Wedding',
            image: 'https://www.phopet.com/images/landing/hero/image_4.jpg', // Replace with your own images
            description: 'Join us as we capture the love and joy of this unforgettable wedding day.',
            link: '#', // Link to detailed story
        },
        {
            title: 'Adventure in the Mountains',
            image: 'https://www.phopet.com/images/landing/hero/image_3.jpg',
            description: 'An exhilarating journey through breathtaking landscapes and stunning vistas.',
            link: '#',
        },
        {
            title: 'A Day at the Beach',
            image: 'https://www.phopet.com/images/landing/hero/image_1.jpg',
            description: 'Experience the warmth of the sun and the beauty of the ocean in our beach photography.',
            link: '#',
        },
        {
            title: 'City Life: Urban Explorations',
            image: 'https://www.phopet.com/images/landing/hero/image_2.jpg',
            description: 'Explore the vibrant life of the city through our lens, capturing its essence.',
            link: '#',
        },
    ];

    return (
        <>
        <Navbar className='mb-10'/>
        <div className="py-16 bg-[#fff9f3] mt-10">
            <h2 className="text-4xl font-bold text-center text-[#e5671b] mb-10">
                Photography Stories
            </h2>

            <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
                {stories.map((story, index) => (
                    <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                        <img
                            src={story.image}
                            alt={story.title}
                            className="w-full h-40 object-cover"
                        />
                        <div className="p-4">
                            <h3 className="text-lg font-semibold mb-2">{story.title}</h3>
                            <p className="text-gray-700 mb-4">{story.description}</p>
                            <a
                                href={story.link}
                                className="inline-block px-4 py-2 bg-[#e5671b] text-white rounded-md hover:bg-[#d45f1a]"
                            >
                                Read More
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Footer/>
        </>
    );
};

export default Stories;
