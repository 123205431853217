import React, { useEffect, useState } from 'react';
import EditorLogo from "../assets/logo/EditorAruLogoPurple.png";

function Preloader() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false); // State for fade-out animation

  useEffect(() => {
    // Set preloader time (in milliseconds)
    const timer = setTimeout(() => {
      setFadeOut(true); // Start fade-out effect after 5 seconds
      setTimeout(() => {
        setIsLoading(false); // Hide preloader after fade-out completes
      }, 600); // Wait for 0.5 seconds before removing preloader from the DOM
    }, 6000); // 5000ms = 5 seconds

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  if (!isLoading) {
    return null; // Return null once loading is complete, the preloader disappears
  }

  return (
    <div
      className={`preloader-container flex justify-center items-center h-screen bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] ${fadeOut ? 'opacity-0 translate-y-10 transition-all duration-500' : ''}`}
    >
      <div className="flex flex-col items-center">
        {/* Your Logo */}
        <img 
          src={EditorLogo} 
          alt="Logo" 
          className="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 mb-4" // responsive size
        />
        
        {/* Loading Text */}
        <p className="text-xl sm:text-2xl md:text-3xl text-center p-5 font-semibold text-gray-600">
          By <br /> Aryan Sadashiv
        </p>
        
        {/* Spinning Animation */}
        <div className="spinner-border animate-spin inline-block w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 border-4 border-t-4 border-purple-800 rounded-full mt-4"></div>
      </div>
    </div>
  );
}

export default Preloader;
