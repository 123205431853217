import React, { Suspense, lazy } from 'react';
import Preloader from './components/Preloader'; // Import Preloader

// Lazy load your components
const Navbar = lazy(() => import('./constants/Navbar'));
const Footer = lazy(() => import('./constants/Footer'));
const Hero = lazy(() => import('./components/Hero'));
const About = lazy(() => import('./components/About'));
const ProductSlider = lazy(() => import('./components/ProductSlider'));
const PortraitsHides = lazy(() => import('./components/PortraitsHides'));
const ProgressSlider = lazy(() => import('./components/ProgressSlider'));

function App() {
  return (
    <div className="bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] overflow-x-hidden antialiased selection:bg-cyan-300 selection:text-cyan-700">
      <Suspense fallback={<Preloader />}> {/* Replace Suspense fallback with Preloader */}
        <Navbar />
        <Hero />
        <PortraitsHides />
        <About />
        <ProductSlider />
        <Footer />
        <ProgressSlider />
      </Suspense>
    </div>
  );
}

export default App;
