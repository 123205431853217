import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import ProgressSlider from '../components/ProgressSlider';

const Services = () => {
    const services = [
        {
            title: 'Wedding Photography',
            description: 'Capture your once-in-a-lifetime moments with high-quality photos. From candid moments to posed shots, we ensure every detail is perfectly preserved.',
            icon: '💍',
            bgColor: 'bg-gradient-to-r from-indigo-100 to-indigo-200',
            features: [
                'Candid and posed shots',
                'High-resolution images',
                'Customized packages',
                'Full-day coverage',
            ],
        },
        {
            title: 'Portrait Photography',
            description: 'Professional portraits that bring out your best self, whether for business, personal, or family use.',
            icon: '📸',
            bgColor: 'bg-gradient-to-r from-green-100 to-green-200',
            features: [
                'Studio and outdoor sessions',
                'Flattering lighting techniques',
                'Personalized styling advice',
                'Quick turnaround times',
            ],
        },
        {
            title: 'Event Photography',
            description: 'Document your events with vibrant and memorable images, whether it\'s a birthday, corporate event, or any special occasion.',
            icon: '🎉',
            bgColor: 'bg-gradient-to-r from-pink-100 to-pink-200',
            features: [
                'Live event coverage',
                'Multiple camera angles',
                'Instant social media sharing',
                'Timely delivery of images',
            ],
        },
        {
            title: 'Product Photography',
            description: 'Showcase your products with eye-catching visuals, perfect for e-commerce, advertising, and marketing.',
            icon: '🛍️',
            bgColor: 'bg-gradient-to-r from-purple-100 to-purple-200',
            features: [
                'Professional studio setup',
                'High-quality lighting',
                'Perfect for online stores',
                'Detailed close-up shots',
            ],
        },
    ];

    return (
        <>
            <Navbar className="mb-10" />
            <div className="pt-24 py-16 bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] mt-10">
                <h2 className="text-4xl font-bold text-center text-purple-800 mb-10">
                    Our Services
                </h2>
                <div className="flex flex-col md:flex-row flex-wrap justify-center items-center gap-8 px-4">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className={`w-full md:w-64 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 ${service.bgColor}`}
                        >
                            {/* Service Icon */}
                            <div className="text-5xl text-center mb-4">
                                {service.icon}
                            </div>
                            {/* Service Title */}
                            <h3 className="text-2xl font-semibold text-center text-purple-800 mb-4">
                                {service.title}
                            </h3>
                            {/* Service Description */}
                            <p className="text-center text-gray-700 text-sm mb-4">
                                {service.description}
                            </p>
                            {/* Features List */}
                            <ul className="text-left text-gray-600 text-sm mb-6 mx-4">
                                {service.features.map((feature, idx) => (
                                    <li key={idx} className="mb-2 flex items-center">
                                        <span className="mr-2 text-purple-800">•</span>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            {/* Explore Button */}
                            <div className="text-center">
                                <Link
                                    to="/pricing"
                                    className="w-full py-2 px-4 rounded-md text-white bg-purple-800 hover:bg-purple-700 transition-colors mb-4 inline-block"
                                >
                                    Explore
                                </Link>
                               
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <ProgressSlider/>
        </>
    );
};

export default Services;
