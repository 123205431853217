import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navlogo from '../assets/logo/EditorAruLogoPurple.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faMeta, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Close the menu on route change
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the route changes
        setIsOpen(false); // Close the menu when the route changes
    }, [location]);

    const closeMenu = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    return (
        <nav className="flex items-center justify-between p-5 bg-[#fff9f3] text-purple-800 fixed top-0 left-0 w-full z-50 shadow-lg rounded-b-xl" style={{ height: '80px' }}>
            {/* Left navigation links */}
            <div className="hidden md:flex space-x-6 text-lg font-semibold items-center">
                <Link to="/gallery" className="hover:underline" onClick={closeMenu}>Gallery</Link>
                <Link to="/pricing" className="hover:underline" onClick={closeMenu}>Pricing</Link>
                <Link to="/services" className="hover:underline" onClick={closeMenu}>Services</Link>
            </div>

            {/* Logo */}
            <div className="flex justify-center items-center flex-grow">
                <Link to="/">
                    <img src={Navlogo} alt="EditorAru logo" className="h-16" />
                </Link>
            </div>

            {/* Right navigation links for larger screens */}
            <div className="hidden md:flex items-center space-x-6">
                <div className="flex space-x-4">
                    <a href="https://www.facebook.com/Editoraruu?mibextid=ZbWKwL">
                        <FontAwesomeIcon icon={faMeta} className="text-xl hover:text-purple-800" />
                    </a>
                    <a href="https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r">
                        <FontAwesomeIcon icon={faInstagram} className="text-xl hover:text-purple-800" />
                    </a>
                    <a href="https://wa.me/message/G5MWMW3SE727A1">
                        <FontAwesomeIcon icon={faWhatsapp} className="text-xl hover:text-purple-800" />
                    </a>
                </div>

                <button className="p-3 bg-purple-800 text-white font-semibold rounded-lg hover:bg-purple-700">
                    <Link to="/getintouch" onClick={closeMenu}>Book Your Shoot</Link>
                </button>
            </div>

            {/* Hamburger menu button for small screens */}
            <div className="md:hidden flex items-center">
                <button onClick={toggleMenu} className="text-2xl focus:outline-none">
                    {isOpen ? '✖️' : '☰'}
                </button>
            </div>

            {/* Animated dropdown menu */}
            <div
                className={`fixed top-0 right-0 w-3/4 max-w-xs h-full bg-[#fff9f3] shadow-lg z-50 transform transition-transform duration-300 ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <div className="flex flex-col items-center space-y-6 p-6">
                    <button
                        className="absolute top-4 right-4 text-xl text-purple-800"
                        onClick={closeMenu}
                    >
                        ✖️
                    </button>
                    <Link to="/gallery" className="hover:underline text-lg font-semibold" onClick={closeMenu}>Gallery</Link>
                    <Link to="/pricing" className="hover:underline text-lg font-semibold" onClick={closeMenu}>Pricing</Link>
                    <Link to="/services" className="hover:underline text-lg font-semibold" onClick={closeMenu}>Services</Link>
                    <div className="flex space-x-4">
                        <a href="https://www.facebook.com/Editoraruu?mibextid=ZbWKwL">
                            <FontAwesomeIcon icon={faMeta} className="text-xl hover:text-purple-800" />
                        </a>
                        <a href="https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r">
                            <FontAwesomeIcon icon={faInstagram} className="text-xl hover:text-purple-800" />
                        </a>
                        <a href="https://wa.me/message/G5MWMW3SE727A1">
                            <FontAwesomeIcon icon={faWhatsapp} className="text-xl hover:text-purple-800" />
                        </a>
                    </div>
                    <button
                        className="p-2 w-full text-center bg-purple-800 text-white rounded-lg hover:bg-purple-800"
                        onClick={closeMenu}
                    >
                        <Link to="/getintouch">Book Your Shoot</Link>
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
