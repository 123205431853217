import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';
import ProgressSlider from '../components/ProgressSlider';

const Pricing = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    const plans = [
        {
            name: 'Basic',
            price: '₹5,000',
            features: ['2-Hour Photoshoot', '10 Edited Photos', 'Online Gallery', '1 Location'],
            buttonLabel: 'Choose Basic',
            bgColor: 'bg-gradient-to-b from-purple-100 to-purple-200',
            icon: '📸', // Icon for Basic Plan
        },
        {
            name: 'Standard',
            price: '₹10,000',
            features: ['4-Hour Photoshoot', '25 Edited Photos', 'Printed Album', '2 Locations'],
            buttonLabel: 'Choose Standard',
            bgColor: 'bg-gradient-to-b from-purple-800 to-purple-900 text-white',
            isPopular: true,
            icon: '⭐', // Icon for Standard Plan
        },
        {
            name: 'Premium',
            price: '₹20,000',
            features: ['Full-Day Shoot', 'Unlimited Photos', 'Deluxe Printed Album', '3 Locations + Studio'],
            buttonLabel: 'Choose Premium',
            bgColor: 'bg-gradient-to-b from-purple-100 to-purple-200',
            icon: '🎥', // Icon for Premium Plan
        },
    ];

    // Function to handle button click and navigate to "Get in Touch" page
    const handleButtonClick = () => {
        navigate('/getintouch'); // Navigate to the /getintouch page
    };

    return (
        <>
            <Navbar className="mb-10" />
            <div className="py-16 bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] min-h-screen mt-10">
                <h2 className="text-4xl font-bold text-center text-purple-800 mb-12">
                    Photography Packages
                </h2>

                <div className="flex flex-col md:flex-row justify-center items-center gap-10">
                    {plans.map((plan, index) => (
                        <div
                            key={index}
                            className={`relative w-80 p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 ${plan.bgColor}`}
                        >
                            {/* Ribbon for Popular Plan */}
                            {plan.isPopular && (
                                <div className="absolute top-0 right-0 bg-gradient-to-r from-yellow-400 to-yellow-500 text-white text-xs font-bold px-4 py-1 rounded-bl-lg shadow-md">
                                    Most Popular
                                </div>
                            )}
                            {/* Plan Icon */}
                            <div className="text-4xl text-center mb-4">
                                {plan.icon}
                            </div>
                            <h3
                                className={`text-2xl font-semibold text-center mb-4 ${
                                    plan.bgColor.includes('text-white')
                                        ? 'text-white'
                                        : 'text-purple-800'
                                }`}
                            >
                                {plan.name}
                            </h3>
                            <p
                                className={`text-3xl font-bold text-center mb-6 ${
                                    plan.bgColor.includes('text-white')
                                        ? 'text-white'
                                        : 'text-purple-800'
                                }`}
                            >
                                {plan.price}
                            </p>
                            <ul
                                className={`mb-8 space-y-4 text-center ${
                                    plan.bgColor.includes('text-white')
                                        ? 'text-white'
                                        : 'text-purple-800'
                                }`}
                            >
                                {plan.features.map((feature, idx) => (
                                    <li key={idx} className="flex items-center justify-center text-lg">
                                        <span className="mr-3">✅</span> {feature}
                                    </li>
                                ))}
                            </ul>
                            <button
                                onClick={handleButtonClick} // Attach the navigation function to the button
                                className={`w-full py-3 px-6 rounded-md font-medium text-lg transition-transform transform hover:scale-110 ${
                                    plan.bgColor.includes('text-white')
                                        ? 'bg-white text-purple-800 hover:bg-purple-700 hover:text-white'
                                        : 'bg-purple-800 text-white hover:bg-purple-700'
                                }`}
                            >
                                {plan.buttonLabel}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <ProgressSlider/>
        </>
    );
};

export default Pricing;
