import React, { useState } from "react";
import Navbar from "../constants/Navbar";
import Footer from "../constants/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeta, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import GetInTouchLogo from "../assets/logo/EditorAruLogoPurple.png";
import ProgressSlider from "../components/ProgressSlider";

// Firebase imports
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAR1_zPlylQ7YV5Xa7UktzCFc-pjyGIsMk",
  authDomain: "editoraru-c31f9.firebaseapp.com",
  databaseURL: "https://editoraru-c31f9-default-rtdb.firebaseio.com", 
  projectId: "editoraru-c31f9",
  storageBucket: "editoraru-c31f9.firebasestorage.app",
  messagingSenderId: "639318925825",
  appId: "1:639318925825:web:2d59b7b1ec19e732df1600",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // to handle submission
  const [error, setError] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Submit Data to Firebase
  const submitData = async (event) => {
    event.preventDefault();
    const { name, email, phone, subject, message } = formData;

    // Validate if all mandatory fields are filled
    if (!name || !email || !phone || !subject || !message) {
      setError("Please fill in all mandatory fields.");
      return;
    }

    const newFormData = {
      name,
      email,
      phone,
      subject,
      message,
      submittedAt: new Date().toISOString(),
    };

    try {
      // Save form data to Firebase Realtime Database
      const reference = ref(db, "clientenquiry/" + Date.now()); // Using timestamp as unique ID
      await set(reference, newFormData);

      // Reset the form and show success modal
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      setIsSubmitted(true); // Show success modal
      setError("");  // Clear any error message
    } catch (error) {
      console.error("Error submitting data:", error);
      setError("An error occurred while submitting your data.");
    }
  };

  // Close the success popup
  const closePopup = () => {
    setIsSubmitted(false);
  };

  return (
    <>
      <Navbar className="mb-10" />
      <section className="bg-[radial-gradient(circle_800px_at_100%_200px,#d5c5ff,transparent)] text-black py-16 px-8 mt-10">
        <div className="max-w-5xl mx-auto flex flex-col lg:flex-row gap-16">
          {/* Left Section - Contact Form */}
          <div className="flex-1">
            <h2 className="text-4xl font-bold mb-8">Contact Me</h2>
            <p className="text-gray-600 mb-12">
              Have any questions or want to book a session? Feel free to reach out.
            </p>
            <form onSubmit={submitData} className="space-y-6">
              <div>
                <label className="block text-gray-600 text-sm mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-100 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-800"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-600 text-sm mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-100 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-800"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-600 text-sm mb-2" htmlFor="phone">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-100 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-800"
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-600 text-sm mb-2" htmlFor="subject">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-100 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-800"
                  placeholder="Subject"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-600 text-sm mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-3 bg-gray-100 text-black rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-800"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-purple-800 hover:bg-purple-700 text-white font-800 py-3 rounded-lg transition duration-200"
              >
                Send Message
              </button>
            </form>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </div>

          {/* Right Section - Company Address */}
          <div className="flex-1 bg-gray-100 p-8 rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold mb-4">Company Address</h1>
            <h3 className="text-xl font-medium text-purple-800 mb-4">
              Editoraru Photography
            </h3>
            <p className="text-gray-600 mb-6">
              CIDCO N-9, <br />
              Chh. Sambhajinagar, MH. IN - 431003
            </p>
            <h3 className="text-xl font-medium text-purple-800 mb-2">Contact Details</h3>
            <p className="text-gray-600">
              Phone: +91 70669 17801 <br />
              Email: editoraru@gmail.com
            </p>
            {/* Social Media Links */}
            <div className="mt-6 flex space-x-4 justify-center">
              <a
                href="https://www.facebook.com/Editoraruu?mibextid=ZbWKwL"
                aria-label="Facebook"
              >
                <FontAwesomeIcon
                  icon={faMeta}
                  className="text-2xl text-purple-600 hover:text-purple-800 transition duration-200"
                />
              </a>
              <a
                href="https://www.instagram.com/editoraru?igsh=dXJ4ZmJ3NHJtNW9r"
                aria-label="Instagram"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-2xl text-purple-600 hover:text-purple-800 transition duration-200"
                />
              </a>
              <a href="https://wa.me/message/G5MWMW3SE727A1" aria-label="WhatsApp">
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="text-2xl text-purple-600 hover:text-purple-800 transition duration-200"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Success Modal */}
      {isSubmitted && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-lg w-80">
            <h3 className="text-xl font-bold text-green-600">Message Sent!</h3>
            <p className="text-gray-700 mt-4">
              Your message has been successfully sent. We'll get back to you soon!
            </p>
            <button
              onClick={closePopup}
              className="mt-6 w-full bg-green-600 hover:bg-green-500 text-white py-2 rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default GetInTouch;
